// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=production` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { Environment } from './interface';

export const environment: Environment = {
  production: false,
  apiBaseUrl: 'https://api-dev.latchaccess.com',
  selectAccount: '254b4f80',
  useMocks: false,
  name: 'dev',
  enableLogging: true,
  datadogToken: 'pub14a05d477f7a66126a4bf7ce1d2c4169',
  enableAnalytics: true,
  heapApplicationId: '709472028',
  reCaptchaToken: '6LesTRgqAAAAAIlT_il4pyqj7NmDfNO7tB580Ust',
  auth0Config: {
    domain: 'auth.dev.latchaccess.com',
    clientId: 'blcYNjHKJuzD8mBEM7EjozKPEwGOSGIF',
    redirectUri: window.location.origin,
    audience: 'https://api-dev.latchaccess.com',
    serverUrl: 'https://api-dev.latchaccess.com',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://api-dev.latchaccess.com/web/v2/user-accounts/password/reset',
          allowAnonymous: true
        },
        'https://api-dev.latchaccess.com/web/*'
        // Ordering seems to matter here, though I can't find documentation of this fact.
        // We want to require tokens for /web/* paths, EXCEPT for /web/v2/user-accounts/password/reset
      ]
    }
  },
  enableFeaturedEntries: true,
  sprigEnvironmentId: 'QTyfLygfc',
  split: {
    core: {
      authorizationKey: '8mmd3s0vjud6ucaguqn8domf1eteae9np6aa',
      key: 'key'
    }
  }
};
